

@media (min-width: 380px) {
    h2.banner__head, .h2.banner__head {
        font-size: 50px;
    }

    h1.banner__head, .h1.banner__head {
        font-size: 60px;
    }
}

@media (min-width: 576px) {
    h1.banner__head, .h1.banner__head {
        font-size: 100px;
    }
}

@media (min-width: 1280px) {
    h1.banner__head, .h1.banner__head {
        font-size: 140px;
    }
}

@media (min-width: 1400px) {
    h2.banner__head, .h2.banner__head {
        font-size: 80px;
    }

    h1.banner__head, .h1.banner__head {
        font-size: 150px;
    }
}

@media (min-width: 1800px) {
    h2.banner__head, .h2.banner__head {
        font-size: 100px;
    }

    h1.banner__head, .h1.banner__head {
        font-size: 200px;
    }
}

.banner__graphic .vjs-control-bar {
    display: none;
}

.more-end {
    text-align: end;
}